import { Palette, Theme, styled } from '@mui/material'
import hexToRgba from 'hex-to-rgba'

const borderColor = (
  palette: Palette,
  selected: boolean = false,
  hover: boolean = false
): string | undefined => {
  if (selected || hover) {
    if (palette.card?.enableBorder !== undefined) {
      return palette.card?.enableBorder
    }
    return palette.primary.main
  }
  return palette.card.border
}

const boxBackgroundColor = (
  palette: Palette,
  selected: boolean = false
): string | undefined => {
  if (selected) {
    if (palette.card?.enable !== undefined) {
      return palette.card?.enable
    }
    return hexToRgba(palette.primary.main, palette.mode === 'dark' ? 0.3 : 0.12)
  }
  return palette.card.main
}

export const InnerBox = styled('div')(
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ({
    theme: { palette },
    selected,
    borderWidth = 1,
    borderWidthEnable = 2
  }: {
    selected: boolean
    theme: Theme
    borderWidth?: number
    borderWidthEnable?: number
  }) => {
    return {
      width: '100%',
      height: 'fit-content',
      padding: selected ? 0 : Math.abs(borderWidthEnable - borderWidth),
      borderWidth: selected ? borderWidthEnable : borderWidth,
      borderStyle: 'solid',
      borderRadius: 'inherit',
      borderColor: borderColor(palette, selected),
      backgroundColor: boxBackgroundColor(palette, selected),
      '&:hover': {
        borderColor: borderColor(palette, selected, true),
        padding: 0,
        borderWidth: borderWidthEnable
      }
    }
  }
)

export const ParentBox = styled('div')(
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ({
    borderRadius,
    staticType = false
  }: {
    staticType?: boolean
    borderRadius: number
  }) => ({
    borderRadius,
    height: 'fit-content',
    margin: 'auto',
    width: staticType ? '100%' : '98%',
    textAlign: 'left',
    cursor: staticType ? 'auto' : 'pointer'
  })
)

export const StaticInnerBox = styled('div')(
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  ({
    theme: { palette },
    borderWidth
  }: {
    theme: Theme
    borderWidth: number
  }) => ({
    borderRadius: 'inherit',
    backgroundColor: palette.card.main,
    border: `${borderWidth}px solid ${palette.card?.border ?? ''}`,
    height: '100%'
  })
)
