/* eslint-disable @typescript-eslint/indent */
import { Company } from '@interface/company'
import { getClient } from '@lib/info'
import { Link, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useAppDispatch } from '@store/hooks'
import { setOpenBanner } from '@store/page'
import { Trans } from 'react-i18next'
import './footer.css'

export interface prop {
  company?: Company
}

interface info {
  name?: string
  address?: string
  postalCode?: string
  city?: string
  region?: string
  vatCode?: string
}

interface contacts {
  telephone?: string
  whatsappNumber?: string | null
  fax?: string
  email?: string
  pec?: string
}

const InfoPunto = ({
  name = '',
  address = '',
  postalCode = '',
  city = '',
  region = '',
  vatCode = ''
}: info): JSX.Element => {
  return (
    <div className="footer-item">
      <Typography variant="footerTitle">{name}</Typography>
      <div className="footer-spacer" />
      {address !== '' && (
        <Typography variant="footerContent">{address}</Typography>
      )}
      {postalCode !== '' && city !== '' && region !== '' && (
        <Typography variant="footerContent">
          {`${postalCode} ${city} (${region})`}
        </Typography>
      )}
      <div className="footer-spacer" />
      {vatCode !== '' && (
        <Typography variant="footerContent">{`P. IVA ${vatCode}`}</Typography>
      )}
    </div>
  )
}

const Contacts = ({
  telephone = '',
  whatsappNumber = '',
  fax = '',
  email = '',
  pec = ''
}: contacts): JSX.Element => {
  return (
    <div className="footer-item">
      <div className="center">
        <Typography variant="footerTitle">
          <Trans>footer.contact</Trans>
        </Typography>
        <div className="footer-spacer" />
        <Typography variant="footerContent">
          <Link
            href={`tel:${telephone}`}
            sx={{ color: 'inherit' }}
          >{`Tel. ${telephone}`}</Link>
        </Typography>

        {whatsappNumber !== undefined &&
          whatsappNumber !== null &&
          whatsappNumber !== '' && (
            <Typography variant="footerContent">
              <Link
                href={`https://wa.me/${whatsappNumber}`}
                sx={{ color: 'inherit' }}
              >
                {`Whatsapp. ${whatsappNumber}`}
              </Link>
            </Typography>
          )}
        {fax !== undefined && fax !== null && fax !== '' && (
          <Typography variant="footerContent">{`FAX. ${fax}`}</Typography>
        )}
        <div className="footer-spacer" />
        <Typography variant="footerContent">
          <Link
            variant="footerContent"
            href={`mailto:${email}`}
            sx={{ color: 'inherit' }}
          >{`Email: ${email}`}</Link>
        </Typography>

        {pec !== undefined && pec !== '' && (
          <Typography variant="footerContent">
            <Link
              variant="footerContent"
              href={`mailto:${pec}`}
              sx={{ color: 'inherit' }}
            >{`PEC: ${pec}`}</Link>
          </Typography>
        )}
      </div>
    </div>
  )
}

const Terms = ({ token }: any): JSX.Element => {
  const dispatch = useAppDispatch()
  return (
    <div className="footer-item">
      <div className="end">
        <Typography variant="footerTitle">
          <Trans>footer.terms</Trans>
        </Typography>
        <div className="footer-spacer" />
        <Link
          color="inherit"
          variant="footerContent"
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          // onClick={() => navigate(getUrl('terms'))}
          target="_blank"
          href={`${window.origin}/${
            process.env.REACT_APP_INITIAL_PATH ?? ''
          }terms/${token}`}
        >
          {'Informativa sul trattamento dei dati personali'}
        </Link>
        <Link
          color="inherit"
          variant="footerContent"
          target="_blank"
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          href={`${window.origin}/${
            process.env.REACT_APP_INITIAL_PATH ?? ''
          }cookie/${token}`}
          // onClick={() => navigate(getUrl('cookie'))}
        >
          {'Cookie Policy'}
        </Link>
        <Link
          color="inherit"
          variant="footerContent"
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          // onClick={() => navigate(getUrl('privacy'))}
          target="_blank"
          href={`${window.origin}/${
            process.env.REACT_APP_INITIAL_PATH ?? ''
          }privacy/${token}`}
        >
          {'Privacy Policy'}
        </Link>
        <Link
          color="inherit"
          variant="footerContent"
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          onClick={() => {
            localStorage.removeItem('appointment_banner_cookie')
            dispatch(setOpenBanner(true))
          }}
        >
          {'Modifica preferenze cookie'}
        </Link>
      </div>
    </div>
  )
}

export const Footer = (props: prop): JSX.Element => {
  const { company } = props
  const { token } = getClient()
  const theme = useTheme()
  return (
    <>
      <div
        className="footerContainer"
        data-testid="footer"
        style={{
          color: theme?.palette?.footer?.text ?? '#fff',
          backgroundColor: theme?.palette?.footer?.main ?? '#000'
        }}
      >
        <div className="footer-items">
          <InfoPunto {...company} />

          <Contacts {...company} />
          <Terms token={token} />
        </div>
      </div>
    </>
  )
}
